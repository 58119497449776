.country {
  color: white;
  height: 7vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.country-img {
  justify-content: center;
  align-items: center;
  display: flex;
}

/* switching component order */
@media (max-width: 767px) {
  #blockContainer {
    display: flex;
    flex-flow: column;
  }
  .country-text {
    order: 3;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .country-icon {
    order: 2;
  
  }
}
