.item {
  height: auto;
  width: 100%;
  margin-top: 10%;
  margin-bottom: 10%;
}

.clients img{
  max-width: fit-content;
}

@media (max-width: 768px) {
  .centering {
    justify-content: center !important;
    display: flex !important;
  }
  .item {
    margin-top:0 !important;
    margin-bottom: 12%;
  }
}
