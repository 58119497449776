.gamecard>img{
    height: 50px;
    width: auto;
    justify-content: center;
    align-self: center;
}

.gamecard:hover {
    filter:invert(47%) sepia(82%) saturate(1883%) hue-rotate(160deg) brightness(93%) contrast(102%);
}
