.owl-stage{
  height:130px !important;
}

.other > img {
  height: 50px;
  width: auto;
  filter: invert(1) !important;
  justify-content: center;
  align-self: center;
}

 img {
  height: 50px;
  margin-bottom: 15px;
  object-fit: contain;
  width: auto;
  justify-content: center;
  align-self: center;
  -webkit-text-fill-color: black !important;
}
.description {
  -webkit-text-fill-color: black !important;
}
.info-title {
  -webkit-text-fill-color: black !important;
}

.info:hover .info-title {
  -webkit-text-fill-color: white !important;
}
.info:hover .description {
  -webkit-text-fill-color: white !important;
}


.info:hover .icon > img  {
    -webkit-text-fill-color: white !important;
    filter: none !important;
    color: white !important; 
    
  }
  
  .info:hover #miss > img  {
    -webkit-text-fill-color: white !important;
    background: none;
    filter: none !important;
    color: white !important; 
  }


  .back{
    background-color: #EAEAEA !important;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-direction: column;
    background-color: #fff;
    padding: 50px 20px;
    -webkit-box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    text-align: center;
    /* margin-bottom: 25px; */
    position: relative;
    overflow: hidden;
  }

  .headingExtra{
    float: left;
    text-align: left;
  }

  .textExtra{
    text-align: left;
  }

  .rightImage{
    height: 100%;
    width: 98%;
    margin-left: 2%;
    margin-right: 2%;
  }


  .item-patners {
   margin: 0 !important;
   padding: 0 !important;
  }

  .item{
    height: 100%;
    justify-content: center;
    align-content: center;
    display: flex;
  }

  .item>img{
    height:auto !important;
    
  }

  .owl-item{
    height:100px !important;
    justify-content: center;
    align-content: center;
    display: flex;
  }

  .contactText {
    text-align: left;
}

.portfolio .items .item-img .item-img-overlay {
  /* background: linear-gradient(to right, #9abef0 0%, #258bc8 100%); */
  background:#0e3d5a;
  /* opacity: 0.8 !important; */
  -webkit-transform: translateY(0) !important;
  transform: translateY(0) !important;
}

@media (max-width: 767px) {
  .textExtra{
    /* margin-left: 5%; */
  }
  .headingExtra{
    padding:0 !important;
    margin: 0;
    margin-left: 3%;

  }
}