.contactText {
    text-align: left;
}

.modal-c {
    font-size: 12px;
    background-color: white;
    border: 1px solid;
    border-radius: 15px;
    border-color: black;
    font-size: 15px;
    font-family: "OpenSans-s", sans-serif;
    width: 200px;
 
  }
  .modal-c > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
  }
  .modal-c > .content {
    width: 100%;
    padding: 10px 5px;
  }
  .modal-c > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
  .modal-c > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }