/* .king-full-image-area{
    height:55vh !important;
} */

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  /* background-color: white; */
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  /* border: 10px solid rgba(255, 255, 255, 0.5); */
  border-radius: 20px;
  background: linear-gradient(to right, #000 0%, #258bc8 100%);
}

.dropdown-content a {
  color: white;
  padding: 5px 16px;
  text-decoration: none;
  display: block;
  /* border-bottom: 2px #258bc8; */
  /* border-radius: 20px; */

  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.dropdown-content .lastDrop {
  border-bottom: none;
}

.dropdown-content a:hover {
  background-color: #258bc8;
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.dropdown:hover .firstDrop {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.dropdown:hover .lastDrop {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.header-appear img {
  filter: invert(62%) sepia(23%) saturate(1300%) hue-rotate(160deg)
    brightness(88%) contrast(92%) !important;
}
.logo:hover img {
  filter: invert(13%) sepia(74%) saturate(617%) hue-rotate(171deg)
    brightness(89%) contrast(93%) !important;
}

.logo > img {
  /* height: 55px !important; */
  height: 100%;
  width: auto;
  margin: 0;
}

.sticky.header-appear .navbar {
  height: 55px;
}

.sticky.header-appear .navbar .logo > img {
  height: 55px;
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container-fluid {
    height: 5vh !important;
  }
  .sticky.header-appear .navbar {
    height: fit-content;
  }

  .king-full-image-area {
    display: flex;
    align-items: end;
  }

  /* .home-contain {
    margin-top: 50%;
  } */
}

@media (max-width: 1200px) {
  .logo > img {
    height: 50px !important;
  }
  
}